.result-page-container {
  min-height: 100dvh;
  display: flex;
  flex-flow: column nowrap;

  header {
    flex-grow: 2;
    @include centerChilds;
    img {
      width: 100%;
      max-width: 400px;
    }
  }
  .swipe-container {
    flex-grow: 1;
    font-size: 4rem;
    text-align: center;
    font-weight: bold;
    font-style: italic;
    color: $white-color;
    p {
      margin-bottom: 20px;
    }

    .p-inputswitch {
      $width: 7rem;
      width: $width;
      height: calc($width/2);
      span:before {
        $spanWidth: calc($width / 2.5);
        width: $spanWidth;
        height: $spanWidth;
        margin-top: calc(-1 * $spanWidth / 2);
      }
      &.p-highlight {
        span:before {
          left: auto;
          right: calc(($width / 2.5) - 20px);
        }
      }
    }
  }

  &:has(.video-container) {
    background: black;
  }
  .video-container {
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    position: absolute;
    inset: 0;
    z-index: 10;

    video {
      width: 100%;
      height: 100%;
    }
  }
}
