* {
  box-sizing: border-box;
}

// Colors
$primary-color: #208fdf;
$secondary-color: #00999d;
$white-color: #ffffff;
// Variables
$nav-height: 0px;

@mixin reset {
  padding: 0;
  margin: 0;
}

body {
  font-family: "Courier New", Courier, monospace;
  position: relative;
  @include reset;
  overflow-x: hidden;
  background: url("../../public/assets/background.jpg") fixed center;
}

// Media
// Small phones
$screen-tn-max: 450px;
// Small tablets and large smartphones (landscape view)
$screen-sm-max: 596px;
// Small tablets (portrait view)
$screen-md-max: 768px;
// Tablets and small desktops
$screen-lg-max: 992px;

// Phone devices
@mixin tn {
  @media (max-width: #{$screen-tn-max}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin centerChilds {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin lineClamp($line-count) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line-count;
  line-clamp: $line-count;
  -webkit-box-orient: vertical;
}

@mixin fillHeight {
  min-height: calc(100dvh - $nav-height);
}

@mixin containerConstraint {
  max-width: 1200px;
  margin-inline: auto;
}

:root {
  font-size: 18px;
  scroll-behavior: smooth;
  @include lg {
    font-size: 18px;
  }

  @include md {
    font-size: 16px;
  }

  @include sm {
    font-size: 14px;
  }

  // @include tn{
  //     font-size: 10px;
  // }
}

.p-inputswitch.p-highlight .p-inputswitch-slider {
  background: rgb(46, 150, 46);
}
