.stage-page {
  header {
    @include centerChilds;
    align-items: flex-end;
    min-height: 30dvh;
    font-size: 6rem;
    font-weight: bold;
    font-style: italic;
    color: $white-color;
  }
  .icons-container {
    min-height: 50dvh;
    @include centerChilds;
    flex-wrap: wrap;
    img {
      cursor: pointer;
      width: 150px;
      margin: 15px;
    }
  }
}
